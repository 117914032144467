<template>
  <div>
    <p class="welcome__description">
      Para presentar, modificar y revisar tus propuestas accede con tus datos o
      regístrate para obtener las credenciasles de acceso.
    </p>
    <validation-observer ref="form">
      <form novalidate autocomplete="off" @submit.prevent="login">
        <validation-provider
          v-slot="{ errors }"
          name="Usuario"
          rules="required"
        >
          <v-text-field
            vid="username"
            v-model="model.username"
            type="username"
            dense
            :error-messages="errors"
            label="Usuario"
            required
          ></v-text-field>
        </validation-provider>

        <validation-provider
          vid="password"
          v-slot="{ errors }"
          name="Contraseña"
          rules="required"
        >
          <v-text-field
            v-model="model.password"
            type="password"
            dense
            :error-messages="errors"
            label="Contraseña"
            required
          ></v-text-field>
        </validation-provider>
        <p class="mb-4 text-right">
          <router-link to="recuperar">¿Olvidaste tu contraseña?</router-link>
        </p>
        <v-btn
          block
          small
          class="elevation-0 text-none w-100"
          color="primary"
          type="submit"
        >Ingresar</v-btn>
        <p class="mt-4">
          ¿No tienes cuenta?
          <router-link to="registro">
            <strong>Regístrate aquí</strong>
          </router-link>
        </p>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { AUTHENTICATE_URL } from "@/constants/ServicesConstants";
import loaderMixin from "@/mixins/loaderMixin";
import sessionMixin from "@/mixins/sessionMixin";
import notificationMixin from '@/mixins/notificationMixin';

export default {
  name: "Login",
  components: {},
  data: () => ({
    authUrl: AUTHENTICATE_URL,
    model: {
      username: "",
      password: "",
    },
  }),
  mixins: [loaderMixin, sessionMixin, notificationMixin],
  methods: {
    async login() {
      const result = await this.$refs.form.validate();
      if (result) {
        this.showLoader();
        this.$axios
          .post(this.authUrl, this.model)
          .then((response) => {
            const user = response.data;
            this.saveToLocalStorage(user);
            this.$router.push({ name: "awardProposals" });
            this.hideLoader();
          })
          .catch((error) => {
            this.hideLoader();
            if (error && error.response && error.response.status == 422) {
              this.invalidFields();
              this.$refs.form.setErrors(error.response.data);
            } else if (error.response && error.response.status == 401) {
              this.$refs.form.errors.password.push("Credenciales invalidas");
            } else {
              this.showError();
            }
          });
      }
    },
  },
};
</script>

